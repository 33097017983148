// Layouts
import MainLayout from "src/pages/layout/Main"
import ResultLayout from "src/pages/layout/Result"

// Pages
import Home from "src/pages/Home"
import Scan from "src/pages/Scan"
import Result from "src/pages/Result"
import NotFound from "src/pages/NotFound"

const routes = [
    {
        path: "/",
        component: MainLayout,
        name: "Home",
        children: [
            {
                path: "",
                name: "Home",
                component: Home
            }
        ]
    },

    {
        path: "/scan",
        component: MainLayout,
        children: [
            {
                path: ":id",
                name: "Scan",
                component: Scan
            }
        ]
    },

    {
        path: "/scan/:id/result",
        component: ResultLayout,
        children: [
            {
                path: "",
                name: "Result",
                component: Result
            }
        ]
    },

    {
        path: "/:pathMatch(.*)*",
        component: NotFound
    }
]

export default routes
