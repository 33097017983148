<template>
    <vue-cookie-accept-decline
        style="padding: 20px; background-color: #ffa082"
        :debug="false"
        :disableDecline="false"
        :showPostponeButton="false"
        elementId="myPanel1"
        position="bottom-left"
        ref="myPanel1"
        transitionName="slideFromBottom"
        type="floating"
    >
        <template #postponeContent>&times;</template>

        <template #message>
            <p class="message">
                We use cookies to ensure you get the best experience on our website.
                <span>
                    <a
                        href="https://cookiesandyou.com/"
                        target="_blank">Learn More...
                    </a>
                </span>
            </p>
        </template>

        <template #declineContent>
            <p class="decline">Decline</p>
        </template>
        <template #acceptContent>
            <p class="accept">Accept!</p>
        </template>
    </vue-cookie-accept-decline>
    <div>
        <div class="header">
            <div class="logo">
                <a href="#" title="Logo"><img src="@/assets/img/logo.png" alt="Logo"/></a>
            </div>
            <div class="right_part_menu">
                <div class="links">
                    <div class="aselect" :data-value="value" :data-list="list">
                        <div class="selector" @click="toggle()">
                            <div class="label">
                                <span>{{ value }}</span>
                            </div>
                            <div class="arrow" :class="{ expanded : visible }"></div>
                            <div :class="{ hidden : !visible, visible }">
                                <ul>
                                    <li :class="{ current : item === value }" v-for="item in list" @click="select(item)">{{ item }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="links">
                    <a href="https://kaduu.io/contact" target="_blank">{{t_contact}}</a>
                </div>
            </div>
        </div>

        <div class="main">
            <div class="title">
                <h1>PortSelfScan</h1>
                <section class="subtitle" >
                    {{ serviceDescription }}
                    <br><br>
                    {{ dataInformation1 }} {{ ipAddress }} {{ dataInformation2 }}
                    <span class="most_common_services cursor-pointer" :title="most_common_services">{{ dataInformationHrefText }}</span>.
                    {{ dataInformation3 }}
                    <br><br>
                    {{ dataInformation4 }}
                </section>
            </div>

            <div class="body">
                <router-view v-slot="{ Component }">
                    <transition name="zoom-in" mode="out-in">
                        <component :is="Component"
                                   :please_click = "please_click"
                                   :agree_title_span = "agree_title_span"
                                   :agree_title_open_pdf_title = "agree_title_open_pdf_title"
                                   :scan_text = "scan_text"
                                   :attention_text = "attention_text"
                                   :vpn_check_text = "vpn_check_text"
                                   :vpn_click_here = "vpn_click_here"
                                   :vpn_click_here_description = "vpn_click_here_description"
                                   :intro_url = "intro_url"
                                   :depth_title1 = "depth_title1"
                                   :depth_desp_1 = "depth_desp_1"
                                   :depth_title2 = "depth_title2"
                                   :depth_desp_2 = "depth_desp_2"
                                   :depth_title3 = "depth_title3"
                                   :depth_desp_3 = "depth_desp_3"
                        />
                    </transition>
                </router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        this.getLanguageInLocalStorage()
        this.select(this.value)
        this.get_user()
        return {
            ipAddress: "...",
            value: this.value,
            list: ["English","German","French", "Italian"],
            visible: false,
            t_contact: this.t_contact,
            serviceDescription: this.serviceDescription,
            dataInformation1: this.dataInformation1,
            dataInformation2: this.dataInformation2,
            dataInformationHrefTitle: this.dataInformationHrefTitle,
            dataInformationHrefText: this.dataInformationHrefText,
            dataInformation3: this.dataInformation3,
            dataInformation4: this.dataInformation4,
            please_click: this.please_click,
            agree_title_span: this.agree_title_span,
            agree_title_open_pdf_title: this.agree_title_open_pdf_title,
            scan_text: this.scan_text,
            most_common_services: this.most_common_services,
            attention_text: this.attention_text,
            vpn_check_text: this.vpn_check_text,
            vpn_click_here: this.vpn_click_here,
            vpn_click_here_description: this.vpn_click_here_description,
            intro_url: this.intro_url,
            depth_title1: this.depth_title1,
            depth_desp_1: this.depth_desp_1,
            depth_title2: this.depth_title2,
            depth_desp_2: this.depth_desp_2,
            depth_title3: this.depth_title3,
            depth_desp_3: this.depth_desp_3,
        }
    },
    methods: {
        setLanguageInLocalStorage(language = "English") {
            localStorage.setItem("language", language)
        },
        getLanguageInLocalStorage() {
            const getLanguageInLocalStorage = localStorage.getItem("language")
            if (!getLanguageInLocalStorage){
                this.setLanguageInLocalStorage()
            }else{
                this.setLanguageInLocalStorage(getLanguageInLocalStorage)
            }
            this.value = localStorage.getItem("language")
        },
        toggle() {
            this.visible = !this.visible
        },
        select(option = "English") {
            if(option == "English"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contact"
                this.serviceDescription = "PortSelfScan is a service that can only be accessed from Switzerland and is provided free of charge to the community. With the aim of protecting us together against cyber security threats."
                this.dataInformation1 = "We investigate whether your publicly accessible, external IP address with which you are currently accessing this page ["
                this.dataInformation2 = "] is vulnerable to hackers. For this purpose, we perform a port scan (UDP & TCP) on the"
                this.dataInformationHrefText = "most common services"
                this.dataInformation3 = "Open services on your router are a risk, as they may be used to access internal confidential data."
                this.dataInformation4 = "Once the scan is finished, you will see the results displayed in your browser. The data is then available for 10 days under a randomly generated link without information of the source-ip."
                this.please_click = "Only click on \"Start test\" if you have deactivated your VPN and you are not in your company network."
                this.agree_title_span = "I agree to have my system scanned from externally "
                this.agree_title_open_pdf_title = "(see Terms, Condition & Privacy)"
                this.scan_text = "Start Test"
                this.most_common_services = "We scan the router for the most common 100 TCP and UDP ports."
                this.attention_text = "Attention: You are accessing this page outside of Switzerland. This service is only provided within Switzerland!"
                this.vpn_check_text = "My VPN is disabled. What is VPN? "
                this.vpn_click_here = "Please click here"
                this.vpn_click_here_description = "A VPN is usually used to connect to the company network from the home office, for example, and thus to access internal company systems. If you are connected to your company via a VPN. Then the displayed IP on this port self-scan page is. That of your company and not your private Internet connection IP address. See the picture for illustration."
                this.intro_url = "PortSelfScan_EN.mp4"
                this.depth_title1 = "Scan only most common ports (100 TCP / 20 UDP)"
                this.depth_desp_1 = "This scan takes 3-5 minutes and is recommended"
                this.depth_title2 = "Scan most common top 1000 TCP & 100 UDP Ports"
                this.depth_desp_2 = "This scan may take up to 30 minutes depending on your infrastructure"
                this.depth_title3 = "can most common top 10000 TCP and 1000 UDP Ports"
                this.depth_desp_3 = "This scan may take up to 4 hours"
            }else if(option == "German"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Kontakt"
                this.serviceDescription = "PortSelfScan ist ein Service, welcher nur aus der Schweiz erreichbar ist und kostenlos der Gesellschaft zur Verfügung gestellt wird. Mit dem Ziel uns gemeinsam gegen Cyber Security Bedrohungen zu schützen."
                this.dataInformation1 = "Wir prüfen, ob Ihre öffentlich zugängliche, externe IP-Adresse, mit der Sie gerade auf diese Seite ["
                this.dataInformation2 = "] zugreifen, von Hackern angegriffen werden kann. Zu diesem Zweck führen wir einen Portscan (UDP & TCP) der"
                this.dataInformationHrefText = "gängigsten Dienste durch"
                this.dataInformation3 = "Offene Dienste auf Ihrem Router stellen ein Risiko dar, da sie für den Zugriff auf interne vertrauliche Daten verwendet werden können."
                this.dataInformation4 = "Sobald der Scan abgeschlossen ist, werden die Ergebnisse in Ihrem Browser angezeigt. Die Daten sind dann für 10 Tage unter einem zufällig generierten Link ohne Angabe der Quell-IP verfügbar."
                this.please_click = "Nur auf \"Test starten\" klicken, wenn sie Ihr VPN deaktiviert haben und Sie nicht in Ihrem Unternehmensnetz sind."
                this.agree_title_span = "Ich bin damit einverstanden, dass mein System von extern gescannt wird "
                this.agree_title_open_pdf_title = "(siehe Allgemeine Geschäftsbedingungen & Datenschutz)"
                this.scan_text = "Test starten"
                this.most_common_services = "Wir scannen den Router nach den 100 häufigsten TCP- und UDP-Ports."
                this.attention_text = "Achtung: Sie greifen auf diese Seite von ausserhalb der Schweiz zu.Dieser Service wird nur innerhalb der Schweiz angeboten!"
                this.vpn_check_text = "Mein VPN ist deaktiviert. Was ist ein VPN? "
                this.vpn_click_here = "Bitte hier klicken"
                this.vpn_click_here_description = "Ein VPN wird in der Regel dazu verwendet, um z.B. aus dem Home-Office sich mit dem Unternehmensnetz zu verbinden und somit auf Unternehmens interne Systeme zugreifen zu können. Wenn Sie über ein VPN mit Ihrem Unternehmen verbunden sind. Dann ist die angezeigte IP auf dieser Port-Self-Scan Seite. Die Ihres Unternehmens und nicht Ihre private Internet-Anschluss IP-Adresse. Siehe dazu das Bild zur Veranschaulichung."
                this.intro_url = "PortSelfScan_DE.mp4"
                this.depth_title1 = "Scannen Sie nur die gängigsten Ports (100 TCP / 20 UDP)"
                this.depth_desp_1 = "Dieser Scan dauert 3-5 Minuten und wird empfohlen"
                this.depth_title2 = "Scannen der 1000 häufigsten TCP- und 100 UDP-Ports"
                this.depth_desp_2 = "Dieser Scan kann je nach Ihrer Infrastruktur bis zu 30 Minuten dauern"
                this.depth_title3 = "Scannen der gängigsten 10000 TCP- und 1000 UDP-Ports"
                this.depth_desp_3 = "Dieser Scan kann bis zu 4 Stunden dauern"
            }else if(option == "French"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contact"
                this.serviceDescription = "PortSelfScan est un service accessible uniquement en Suisse et mis gratuitement à la disposition de la société. Le but est de nous protéger ensemble contre les menaces de cybersécurité."
                this.dataInformation1 = "Nous vérifions si votre adresse IP externe, accessible au public, avec laquelle vous accédez actuellement à cette page ["
                this.dataInformation2 = "], peut être attaquée par des hackers. À cette fin, nous effectuons un scan des ports (UDP et TCP) sur les"
                this.dataInformationHrefText = "services les plus courants"
                this.dataInformation3 = "Les services ouverts sur votre routeur représentent un risque, car ils peuvent être utilisés pour accéder à des données confidentielles internes."
                this.dataInformation4 = "Une fois l'analyse terminée, vous verrez les résultats s'afficher dans votre navigateur. Les données sont alors disponibles pendant 10 jours sous un lien généré aléatoirement sans information sur l'IP source."
                this.please_click = "Ne cliquez sur \"Démarrer le test\" que si vous avez désactivé votre VPN et que vous n'êtes pas sur le réseau de votre entreprise."
                this.agree_title_span = "Je suis d'accord pour que mon système soit scanné depuis l'extérieur "
                this.agree_title_open_pdf_title = "(voir Termes, conditions et confidentialité)"
                this.scan_text = "Démarrer le test"
                this.most_common_services = "Nous scannons le routeur pour les 100 ports TCP et UDP les plus connus."
                this.attention_text = "Attention: Vous accédez à cette page en dehors de la Suisse. Ce service n'est fourni qu'en Suisse!"
                this.vpn_check_text = "Mon VPN est désactivé. Qu'est-ce qu'un VPN? "
                this.vpn_click_here = "Veuillez cliquer ici"
                this.vpn_click_here_description = "Un VPN est généralement utilisé pour se connecter au réseau de l'entreprise, par exemple depuis le bureau à domicile, et ainsi accéder aux systèmes internes de l'entreprise. Si vous êtes connecté à votre entreprise via un VPN. Dans ce cas, l'IP affichée sur cette page Port-Self-Scan. Il s'agit de celle de votre entreprise et non de votre adresse IP privée. Voir l'image pour une illustration."
                this.intro_url = "PortSelfScan_FR.mp4"
                this.depth_title1 = "Analyse uniquement des ports les plus courants (100 TCP / 20 UDP)"
                this.depth_desp_1 = "Cette analyse prend 3 à 5 minutes et est recommandée"
                this.depth_title2 = "Analyse des 1000 principaux ports TCP et 100 ports UDP les plus courants"
                this.depth_desp_2 = "Cette analyse peut prendre jusqu'à 30 minutes en fonction de votre infrastructure"
                this.depth_title3 = "Analyse des 10000 principaux ports TCP et 1000 ports UDP les plus courants"
                this.depth_desp_3 = "Ce scan peut prendre jusqu'à 4 heures"
            }else if(option == "Italian"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contatto"
                this.serviceDescription = "Il PortSelfScan è un servizio accessibile solo dalla Svizzera e fornito gratuitamente alla società. Con l'obiettivo di proteggerci insieme dalle minacce alla sicurezza informatica."
                this.dataInformation1 = "Verifichiamo se il vostro indirizzo IP esterno, accessibile pubblicamente, con cui state accedendo a questa pagina ["
                this.dataInformation2 = "] possa essere attaccato da hacker. A tal fine, eseguiamo una scansione delle porte (UDP e TCP) sui"
                this.dataInformationHrefText = "servizi più comuni"
                this.dataInformation3 = "I servizi aperti sul router rappresentano un rischio, in quanto possono essere utilizzati per accedere a dati riservati interni."
                this.dataInformation4 = "Al termine della scansione, i risultati vengono visualizzati nel browser. I dati sono disponibili per 10 giorni sotto un link generato casualmente senza informazioni sull'IP di origine."
                this.please_click = "Cliccate su \"Avvia test\" solo se avete disattivato la VPN e non vi trovate nella rete societaria."
                this.agree_title_span = "Accetto che il mio sistema venga scansionato dall'esterno "
                this.agree_title_open_pdf_title = "(vedere Termini, condizioni e privacy)"
                this.scan_text = "Avvia il test"
                this.most_common_services = "Eseguiamo una scansione del router per le 100 porte TCP e UDP più comuni."
                this.attention_text = "Attenzione: State accedendo a questa pagina al di fuori della Svizzera. Questo servizio viene fornito solo in Svizzera!"
                this.vpn_check_text = "La mia VPN è disattivata. Cos'è una VPN? "
                this.vpn_click_here = "Clicca qui"
                this.vpn_click_here_description = "Una VPN viene solitamente utilizzata per connettersi alla rete aziendale dall'ufficio di casa, ad esempio, e quindi per accedere ai sistemi aziendali interni. Se siete collegati alla vostra azienda tramite una VPN. L'IP visualizzato in questa pagina di autoscansione della porta è. Si tratta dell'indirizzo IP dell'azienda e non dell'indirizzo IP della connessione Internet privata. Vedere l'immagine per illustrare questo aspetto."
                this.intro_url = "PortSelfScan_IT.mp4"
                this.depth_title1 = "Scansione solo delle porte più comuni (100 TCP / 20 UDP)"
                this.depth_desp_1 = "Questa scansione richiede 3-5 minuti ed è consigliata"
                this.depth_title2 = "Scansione delle 1000 porte TCP e 100 porte UDP più comuni"
                this.depth_desp_2 = "Questa scansione può richiedere fino a 30 minuti, a seconda della vostra infrastruttura"
                this.depth_title3 = "Scansione delle prime 10000 porte TCP e 1000 UDP più comuni"
                this.depth_desp_3 = "Questa scansione può richiedere fino a 4 ore"
            }
        },
        get_user(){
            this.axios({
                method: "get",
                url: "/client"
            }).then((response) => {
                this.ipAddress = response.data.ip
                localStorage.setItem("myip", this.ipAddress)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/mainlayout.scss";
</style>
