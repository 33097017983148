<template>
    <div>
        <div class="header">
            <div class="logo">
                <a href="#" title="Logo"><img src="@/assets/img/logo.png" alt="Logo"/></a>
            </div>
            <div class="right_part_menu">
                <div class="links">
                    <div class="aselect" :data-value="value" :data-list="list">
                        <div class="selector" @click="toggle()">
                            <div class="label">
                                <span>{{ value }}</span>
                            </div>
                            <div class="arrow" :class="{ expanded : visible }"></div>
                            <div :class="{ hidden : !visible, visible }">
                                <ul>
                                    <li :class="{ current : item === value }" v-for="item in list" @click="select(item)">{{ item }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="links">
                    <a href="https://kaduu.io/contact" target="_blank">{{t_contact}}</a>
                </div>
            </div>
        </div>

        <div class="main">
            <div class="title">
                <h2>PortSelfScan</h2>
            </div>

            <div class="body">
                <router-view v-slot="{ Component }">
                    <transition name="zoom-in" mode="out-in">
                        <component :is="Component"
                                   :t_service = "t_service"
                                   :t_details = "t_details"
                                   :t_recommendation = "t_recommendation"
                                   :t_recommendation1 = "t_recommendation1"
                                   :t_recommendation2 = "t_recommendation2"
                                   :t_recommendation3 = "t_recommendation3"
                                   :t_recommendation4 = "t_recommendation4"
                                   :t_recommendation_swisscom = "t_recommendation_swisscom"
                                   :t_recommendation_community = "t_recommendation_community"
                                   :t_recommendation_fiber = "t_recommendation_fiber"
                                   :t_recommendation_upc = "t_recommendation_upc"
                                   :t_recommendation_sunrise = "t_recommendation_sunrise"
                                   :t_recommendation_otherwise = "t_recommendation_otherwise"
                                   :t_copy_result = "t_copy_result"
                                   :t_copy_result_description_1 = "t_copy_result_description_1"
                                   :t_copy_result_description_2 = "t_copy_result_description_2"
                                   :t_descriptions = "t_descriptions"
                        />
                    </transition>
                </router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        this.getLanguageInLocalStorage()
        this.select(this.value)
        return {
            value: this.value,
            list: ["English","German","French", "Italian"],
            visible: false,
            t_contact: this.t_contact,
            t_service: this.t_service,
            t_details: this.t_details,
            t_recommendation: this.t_recommendation,
            t_recommendation1: this.t_recommendation1,
            t_recommendation2: this.t_recommendation2,
            t_recommendation3: this.t_recommendation3,
            t_recommendation4: this.t_recommendation4,
            t_recommendation_swisscom: this.t_recommendation_swisscom,
            t_recommendation_community: this.t_recommendation_community,
            t_recommendation_fiber: this.t_recommendation_fiber,
            t_recommendation_upc: this.t_recommendation_upc,
            t_recommendation_sunrise: this.t_recommendation_sunrise,
            t_recommendation_otherwise: this.t_recommendation_otherwise,
            t_copy_result: this.t_copy_result,
            t_copy_result_description_1: this.t_copy_result_description_1,
            t_copy_result_description_2: this.t_copy_result_description_2,
            t_descriptions: this.t_descriptions
        }
    },
    methods: {
        setLanguageInLocalStorage(language = "English") {
            localStorage.setItem("language", language)
        },
        getLanguageInLocalStorage() {
            this.value = localStorage.getItem("language")
        },
        toggle() {
            this.visible = !this.visible
        },
        select(option = "English") {
            if(option == "English"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contact"
                this.t_service = "Service"
                this.t_details = "Details"
                this.t_recommendation = "Recommendation"
                this.t_recommendation1 = "Each router is different, so it varies according to make and model. With that in mind, we have tried to keep this guideline as universal as possible. First you need to access the admin panel of your router. In most cases, you can access the router’s settings page by inputting your router’s IP address in the address bar of a web browser. If you don't know your routers IP address, you can open a terminal (for example type cmd in your windows search) and enter the command ipconfig. You should see an output with different entries. The default gateway IP address is usually your router."
                this.t_recommendation2 = "Once on the settings page, look for port options, otherwise called port forwarding options. This will be called something different depending on the brand of your wireless router, but it will usually be named something related to ports, port forwarding, or virtual servers. Turn off whichever ports you desire and be sure to save your changes.  Check also your router's firewall settings. If a port is open and you would prefer it not to be, this may be because your wireless router's firewall is turned off."
                this.t_recommendation3 = "Restart your router: When you've removed any reference within your router to the ports being open, restart your router just to be sure. Now when you check your open ports again, they should appear closed."
                this.t_recommendation4 = "Here are additional support links from some Swiss Internet service providers."
                this.t_recommendation_swisscom = "https://www.swisscom.ch/de/res/hilfe/geraet/internet-router.html?campID=shortcut-internetbox-hilfe"
                this.t_recommendation_community = "https://community.swisscom.ch/t5/Internet-Wissensbox/tkb-p/tkb_internet_de"
                this.t_recommendation_fiber = "https://fiber.salt.ch/de/help/home/internet"
                this.t_recommendation_upc = "https://www.upc.ch/de/support/internet/"
                this.t_recommendation_sunrise = "https://www.sunrise.ch/en/support/internet/set-up-internet/use-modem-device-overview"
                this.t_recommendation_otherwise = "Otherwise simply \"google\" the router type and how this can be configured securely."
                this.t_copy_result = "Copy Results"
                this.t_copy_result_description_1 = "You can copy the results to the scan in a link"
                this.t_copy_result_description_2 = "which will be valid for the next 10 days"
                this.t_descriptions =  [
                    {
                        rating: 7,
                        title: "Danger!",
                        description: "The scanner detected severe vulnerabilities."
                    },
                    {
                        rating: 3,
                        title: "Warning!",
                        description: "The scanner detected a few possible vulnerabilities."
                    },
                    {
                        rating: 0,
                        title: "Result",
                        description: "No open ports could be detected from externally within the given port range."
                    }
                ]
            }else if(option == "German"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Kontakt"
                this.t_service = "Dienst"
                this.t_details = "Details"
                this.t_recommendation = "Empfehlung"
                this.t_recommendation1 = "Jeder Router ist anders, so dass es je nach Marke und Modell Unterschiede gibt. Aus diesem Grund haben wir versucht, diesen Leitfaden so allgemein wie möglich zu halten. Zunächst müssen Sie auf die Verwaltungskonsole Ihres Routers zugreifen. In den meisten Fällen können Sie auf die Einstellungsseite des Routers zugreifen, indem Sie die IP-Adresse des Routers in die Adressleiste eines Webbrowsers eingeben. Wenn Sie die IP-Adresse Ihres Routers nicht kennen, können Sie ein Terminal öffnen (geben Sie z. B. cmd in Ihre Windows-Suche ein) und den Befehl ipconfig eingeben. Sie sollten eine Ausgabe mit verschiedenen Einträgen sehen. Die IP-Adresse des Standardgateways ist normalerweise die Ihres Routers."
                this.t_recommendation2 = "Auf der Einstellungsseite suchen Sie nach Portoptionen, auch Portweiterleitungsoptionen genannt. Je nach Marke Ihres drahtlosen Routers hat dies einen anderen Namen, aber in der Regel hat es etwas mit Ports, Portweiterleitung oder virtuellen Servern zu tun. Deaktivieren Sie die gewünschten Ports, und speichern Sie die Änderungen. Überprüfen Sie auch die Firewall-Einstellungen Ihres Routers. Wenn ein Port offen ist, obwohl Sie ihn lieber nicht geöffnet hätten, kann das daran liegen, dass die Firewall Ihres Routers ausgeschaltet ist."
                this.t_recommendation3 = "Starten Sie Ihren Router neu: Wenn Sie alle Hinweise auf offene Ports in Ihrem Router entfernt haben, starten Sie den Router neu, um sicherzugehen. Wenn Sie nun die offenen Ports erneut überprüfen, sollten sie geschlossen erscheinen."
                this.t_recommendation4 = "Hier finden Sie zusätzliche Support- Links von einigen Schweizer Internetanbietern."
                this.t_recommendation_swisscom = "https://www.swisscom.ch/de/res/h ilfe/geraet/internet- router.html?campID=shortcut- internetbox-hilfe"
                this.t_recommendation_community = "https://community.swisscom.ch/t5/Internet-Wissensbox/tkb- p/tkb_internet_de"
                this.t_recommendation_fiber = "https://fiber.salt.ch/de/help/home/internet"
                this.t_recommendation_upc = "https://www.upc.ch/de/support/in ternet/"
                this.t_recommendation_sunrise = "https://www.sunrise.ch/en/suppor t/internet/set-up-internet/use- modem-device-overview"
                this.t_recommendation_otherwise = "Ansonsten \"googeln\" Sie einfach nach dem Routertyp und wie dieser sicher konfiguriert werden kann."
                this.t_copy_result = "Ergebnisse kopieren"
                this.t_copy_result_description_1 = "Sie können die Ergebnisse des Scans in einen Link kopieren,"
                this.t_copy_result_description_2 = "der für die nächsten 10 Tage gültig sein wird"
                this.t_descriptions =  [
                    {
                        rating: 7,
                        title: "Danger!",
                        description: "The scanner detected severe vulnerabilities."
                    },
                    {
                        rating: 3,
                        title: "Warnung!",
                        description: "Der Scanner hat einige mögliche Sicherheitslücken gefunden."
                    },
                    {
                        rating: 0,
                        title: "Ergebnis",
                        description: "Es konnten keine offenen Ports von extern innerhalb des angegebenen Portbereichs festgestellt werden."
                    }
                ]
            }else if(option == "French"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contact"
                this.t_service = "Service"
                this.t_details = "Détails"
                this.t_recommendation = "Recommandation"
                this.t_recommendation1 = "Chaque routeur est différent, il varie donc en fonction de la marque et du modèle. En gardant cela à l'esprit, nous avons essayé de garder cette ligne directrice aussi universelle que possible. Tout d'abord, vous devez accéder au panneau d'administration de votre routeur. Dans la plupart des cas, vous pouvez accéder à la page des paramètres du routeur en saisissant l'adresse IP de votre routeur dans la barre d'adresse d'un navigateur Web. Si vous ne connaissez pas l'adresse IP de votre routeur, vous pouvez ouvrir un terminal (par exemple, tapez cmd dans votre recherche Windows) et entrer la commande ipconfig. Vous devriez voir une sortie avec différentes entrées. L'adresse IP de la passerelle par défaut est généralement celle de votre routeur."
                this.t_recommendation2 = "Une fois sur la page des paramètres, recherchez les options de port, autrement appelées options de transfert de port. Le nom de cette option varie en fonction de la marque de votre routeur sans fil, mais il s'agit généralement d'un nom lié aux ports, à la redirection de port ou aux serveurs virtuels. Désactivez tous les ports que vous souhaitez et assurez- vous de sauvegarder vos modifications. Vérifiez également les paramètres du pare-feu de votre routeur. Si un port est ouvert alors que vous préféreriez qu'il ne le soit pas, c'est peut-être parce que le pare-feu de votre routeur sans fil est désactivé."
                this.t_recommendation3 = "Redémarrez votre routeur : Une fois que vous avez supprimé toute référence à l'ouverture des ports dans votre routeur, redémarrez ce dernier pour être sûr. Lorsque vous vérifiez à nouveau vos ports ouverts, ils devraient apparaître fermés."
                this.t_recommendation4 = "Voici des liens d'assistance supplémentaires de certains fournisseurs de services Internet suisses."
                this.t_recommendation_swisscom = "https://www.swisscom.ch/de/res/h ilfe/geraet/internet- router.html?campID=shortcut- internetbox-hilfe"
                this.t_recommendation_community = "https://community.swisscom.ch/t5/Internet-Wissensbox/tkb- p/tkb_internet_de"
                this.t_recommendation_fiber = "https://fiber.salt.ch/de/help/home/internet"
                this.t_recommendation_upc = "https://www.upc.ch/de/support/in ternet/"
                this.t_recommendation_sunrise = "https://www.sunrise.ch/en/suppor t/internet/set-up-internet/use- modem-device-overview"
                this.t_recommendation_otherwise = "Sinon, il suffit de \"googler\" le type de routeur et la façon dont il peut être configuré de manière sécurisée."
                this.t_copy_result = "Copie des résultats"
                this.t_copy_result_description_1 = "Vous pouvez copier les résultats de l'analyse dans un lien"
                this.t_copy_result_description_2 = "qui sera valable pour les 10 prochains jours"
                this.t_descriptions =  [
                    {
                        rating: 7,
                        title: "Danger!",
                        description: "The scanner detected severe vulnerabilities."
                    },
                    {
                        rating: 3,
                        title: "Attention!",
                        description: "Le scanner a détecté quelques vulnérabilités possibles."
                    },
                    {
                        rating: 0,
                        title: "Résultat",
                        description: "Aucun port ouvert n'a pu être détecté de l'extérieur dans la zone de port donnée."
                    }
                ]
            }else if(option == "Italian"){
                this.setLanguageInLocalStorage(option)
                this.getLanguageInLocalStorage()
                this.t_contact = "Contatto"
                this.t_service = "Servizio"
                this.t_details = "Dettagli"
                this.t_recommendation = "Raccomandazione"
                this.t_recommendation1 = "Ogni router è diverso, quindi varia a seconda della marca e del modello. Per questo motivo, abbiamo cercato di mantenere questa linea guida il più universale possibile. Per prima cosa è necessario accedere al pannello di amministrazione del router. Nella maggior parte dei casi, è possibile accedere alla pagina delle impostazioni del router inserendo l'indirizzo IP del router nella barra degli indirizzi di un browser web. Se non si conosce l'indirizzo IP del router, si può aprire un terminale (ad esempio digitando cmd nella ricerca di Windows) e inserire il comando ipconfig. Si dovrebbe vedere un output con diverse voci. L'indirizzo IP del gateway predefinito è solitamente quello del router."
                this.t_recommendation2 = "Una volta nella pagina delle impostazioni, cercate le opzioni di porta, altrimenti chiamate opzioni di inoltro della porta. Il nome di questa opzione varia a seconda della marca del router wireless, ma di solito si tratta di un nome legato alle porte, all'inoltro delle porte o ai server virtuali. Disattivate le porte che desiderate e assicuratevi di salvare le modifiche. Controllate anche le impostazioni del firewall del router. Se una porta è aperta e si preferisce che non lo sia, è possibile che il firewall del router wireless sia disattivato."
                this.t_recommendation3 = "Riavviate il router: Una volta rimosso qualsiasi riferimento alle porte aperte all'interno del router, riavviate il router per sicurezza. A questo punto, quando si controllano nuovamente le porte aperte, queste dovrebbero apparire chiuse."
                this.t_recommendation4 = "Ecco ulteriori link di supporto da parte di alcuni fornitori di servizi Internet svizzeri."
                this.t_recommendation_swisscom = "https://www.swisscom.ch/de/res/h ilfe/geraet/internet- router.html?campID=shortcut- internetbox-hilfe"
                this.t_recommendation_community = "https://community.swisscom.ch/t5/Internet-Wissensbox/tkb- p/tkb_internet_de"
                this.t_recommendation_fiber = "https://fiber.salt.ch/de/help/home/internet"
                this.t_recommendation_upc = "https://www.upc.ch/de/support/in ternet/"
                this.t_recommendation_sunrise = "https://www.sunrise.ch/en/suppor t/internet/set-up-internet/use- modem-device-overview"
                this.t_recommendation_otherwise = "Altrimenti, è sufficiente \"cercare su Google\" il tipo di router e il modo in cui questo può essere configurato in modo sicuro."
                this.t_copy_result = "Copia dei risultati"
                this.t_copy_result_description_1 = "È possibile copiare i risultati della scansione in un link"
                this.t_copy_result_description_2 = "valido per i prossimi 10 giorni."
                this.t_descriptions =  [
                    {
                        rating: 7,
                        title: "Danger!",
                        description: "The scanner detected severe vulnerabilities."
                    },
                    {
                        rating: 3,
                        title: "Attenzione!",
                        description: "Lo scanner ha rilevato alcune possibili vulnerabilità."
                    },
                    {
                        rating: 0,
                        title: "Risultato",
                        description: "Non è stato possibile rilevare alcuna porta aperta dall'esterno all'interno dell'intervallo di porte indicato."
                    }
                ]
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/resultlayout.scss";
</style>
