<template>
    <div>
        <div class="intro-video">
            <video controls width="670">
                <source type="video/mp4" :src="intro_url">
            </video>
        </div>
        <div class="home-page">
            <div v-if="is_start_test" class="row d-flex align-items-center form">
                <div class="row radiorow">
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <input
                                type="radio"
                                id="scan_depth_1"
                                v-model="scan_depth"
                                value="dp1"
                            />
                            <label for="scan_depth_1" class="cursor-pointer">
                                <span>{{ depth_title1 }}</span>
                                <div>{{ depth_desp_1 }}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row radiorow">
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <input
                                type="radio"
                                id="scan_depth_2"
                                v-model="scan_depth"
                                value="dp2"
                            />
                            <label for="scan_depth_2" class="cursor-pointer">
                                <span>{{ depth_title2 }}</span>
                                <div>{{ depth_desp_2 }}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row radiorow">
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <input
                                type="radio"
                                id="scan_depth_3"
                                v-model="scan_depth"
                                value="dp3"
                            />
                            <label for="scan_depth_3" class="cursor-pointer">
                                <span>{{ depth_title3 }}</span>
                                <div>{{ depth_desp_3 }}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row checkrow">
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <input
                                id="is_vpn_enabled"
                                type="checkbox"
                                name="is_vpn_enabled"
                                v-model="is_vpn_enabled"
                                @change="check_vpn($event)"
                            />
                            <label for="is_vpn_enabled" class="cursor-pointer">
                                <span>{{vpn_check_text}}</span>
                                <a href="#" v-on:click="toggleVpnDescription">
                                    {{vpn_click_here}}
                                </a>
                            </label>
                        </div>
                    </div>
                </div>

                <div v-if="vpn_description_click">
                    <p class="vpn_description">{{ vpn_click_here_description }}</p>
                    <img class="vpn_description_img" src="@/assets/img/vpn_description.jpg" alt="Logo"/>
                </div>
            </div>
            <div v-else>
                <h3 class="please_click">{{please_click}}</h3>

                <div class="form">
                    <div class="row checkrow">
                        <div class="col">
                            <div class="d-flex justify-content-center">
                                <input id="agree" type="checkbox" name="agree" v-model="agree"/>
                                <label for="agree" class="agreeTitle cursor-pointer">
                                    <span>{{agree_title_span}} </span>
                                    <a href="#" v-on:click="openPdf" class="openPdfTitle">{{agree_title_open_pdf_title}}</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button v-if="countryName" class="scan scan-text" @click="start_test">{{scan_text}}</button>
                    <button v-if="!countryName" class="scan scan-grey scan-text">{{scan_text}}</button>
                    <div v-if="!countryName" class="row">
                        <p class="attention attention-text">
                            {{attention_text}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "please_click",
        "agree_title_span",
        "agree_title_open_pdf_title",
        "scan_text",
        "attention_text",
        "vpn_check_text",
        "vpn_click_here",
        "vpn_click_here_description",
        "intro_url",
        "depth_title1",
        "depth_desp_1",
        "depth_title2",
        "depth_desp_2",
        "depth_title3",
        "depth_desp_3",
    ],

    data() {
        this.getCountry()
        return {
            loading: false,
            agree: false,
            countryName: false,
            is_vpn_enabled: false,
            is_start_test: false,
            vpn_description_click: false,
            scan_depth: false
        }
    },

    methods: {
        start_test() {
            if (!this.agree) {
                alert("You should agree first.")
                return
            }
            this.is_start_test = true
        },

        check_vpn(event) {
            if (this.is_vpn_enabled) {
                if (!this.scan_depth){
                    this.is_vpn_enabled = false
                    alert("You should choose scan depth first.")
                    return
                }
                this.scan()
            }
        },

        scan() {
            console.debug("scan")

            this.loading = true

            this
                .axios({
                    method: "post",
                    url: "/scans",
                    data: {
                        depth:this.scan_depth
                    }
                })
                .then((response) => {
                    console.debug("scan, response=%s", response.data)
                    this.loading = false

                    if (response.data.error) {
                        alert("Error: " + response.data.error)
                    }

                    else {
                        const id = response.data.id
                        this.$router.push({name: "Scan", params: {id: id}})
                    }
                })
                .catch((error) => {
                    this.loading = false
                    alert("Server error: " + error)
                })
        },

        openPdf() {
            window.open("Terms_Conditions_Privacy.pdf", "_blank")
        },

        getCountry() {
            fetch("/app/geo")
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    console.debug("IP geolocation", data.country_code, data)

                    if (data.country_code === "CH" || data.country_code === "DE") {
                        this.countryName = true
                    }
                })
        },

        toggleVpnDescription() {
            this.vpn_description_click = !this.vpn_description_click
        },

    }
}
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/home.scss";
</style>
