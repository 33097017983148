<template>
    <div class="result">
        <div v-if="status === 'FINISHED'">
            <div class="result-header">
                <div class="ampel">
                    <div class="circle" :class="rating >= 7 ? 'red' : ''"></div>
                    <div class="circle" :class="rating >= 3 && rating < 7 ? 'yellow' : ''"></div>
                    <div class="circle" :class="rating < 3 ? 'green' : ''"></div>
                </div>
                <div class="description">
                    <div class="title">{{ title }}</div>
                    <div class="info">{{ description }}</div>
                </div>
            </div>

            <div class="result-table" v-if="ports.length > 0">
                <div class="table-row table-header-row">
                    <div class="table-header service">{{t_service}}</div>
                    <div class="table-header detail">{{t_details}}</div>
                    <div class="table-header recommendation">{{t_recommendation}}</div>
                </div>
                <div class="table-row">
                    <div class="table-childes">
                        <div class="table-child-row" v-for="port in ports">
                            <div class="table-cell service">
                                Port {{ port.port }}
                                <p v-if="port.product">
                                    {{ port.product }} {{ port.version }}
                                </p>
                                <p v-else>
                                    {{ port.service }}
                                </p>
                            </div>
                            <div class="table-cell detail">
                                <span v-if="port.port in helps">
                                    {{ helps[port.port] }}
                                </span>
                                <span v-else>
                                    {{ port.service }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="table-cell recommendation">
                        <span>
                            {{t_recommendation1}}
                            <br>
                            <br>
                            {{t_recommendation2}}
                            <br>
                            <br>
                            {{t_recommendation3}}
                            <br>
                            <br>
                            {{t_recommendation4}}
                            <ul class="list-style-lower-roman">
                                <li>
                                    {{t_recommendation_swisscom}}
                                </li>
                                <li>
                                    {{t_recommendation_community}}
                                </li>
                                <li>
                                    {{t_recommendation_fiber}}
                                </li>
                                <li>
                                    {{t_recommendation_upc}}
                                </li>
                                <li>
                                    {{t_recommendation_sunrise}}
                                </li>
                                <li>
                                    {{t_recommendation_otherwise}}
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>

            <div class="copy-results-block" v-if="ports.length > 0">
                <div class="button-block">
                    <button @click="copyLink">{{t_copy_result}}</button>
                </div>
                <div class="description">
                    {{t_copy_result_description_1}}<br>
                    {{t_copy_result_description_2}}
                </div>
            </div>

            <div class="row mt-5 mb-3">
                <div class="col">
                    <h4>Shodan Search Engine Output from your IP address</h4>
                </div>
            </div>
            <div>
                <div class="result-table" v-if="shodan !== null">
                    <div class="table-row table-header-row">
                        <div class="table-header htcell">Your IP-Address</div>
                        <div class="table-header ptcell">Ports</div>
                        <div class="table-header vlcell">Vulnerabilities</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell htcell">
                            <div>
                                <p class="mb-1">{{ ipAddress }}</p>
                            </div>
                        </div>
                        <div class="table-cell ptcell">
                            <div v-if="shodan.ports !== undefined && shodan.ports.length > 0">
                                <p class="mb-1" v-for="port in shodan.ports">{{ port }}</p>
                            </div>
                            <div v-else>
                                0
                            </div>
                        </div>
                        <div class="table-cell vlcell">
                            <div v-if="shodan.vulns !== undefined && shodan.vulns.length > 0">
                                <p class="mb-1" v-for="vl in shodan.vulns">{{ vl }}</p>
                            </div>
                            <div v-else>
                                0
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="status === 'FAILED'">
            <div class="result-header">
                <div class="ampel">
                    <div class="circle red"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="description">
                    <div class="title">Error!</div>
                    <div class="info">The scan has failed. Please contact the administrator.</div>
                </div>
            </div>
        </div>
        <div v-else-if="status === 'TIMEOUT'">
            <div class="result-header">
                <div class="ampel">
                    <div class="circle red"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="description">
                    <div class="title">Error!</div>
                    <div class="info">The scan encounted a time out. This usually happens if a network device(your router or firewall) starts blocking the scan.<br><br>If you tried a scan with a larger port range, we recommend to try again with a smaller range. If the timeout happens again, your system cannot be scanned from externally.
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="result-header">
                <div class="ampel">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <div class="description">
                    <div class="title">Loading</div>
                    <div class="info">Please wait...</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "t_service",
        "t_details",
        "t_recommendation",
        "t_recommendation1",
        "t_recommendation2",
        "t_recommendation3",
        "t_recommendation4",
        "t_recommendation_swisscom",
        "t_recommendation_community",
        "t_recommendation_fiber",
        "t_recommendation_upc",
        "t_recommendation_sunrise",
        "t_recommendation_otherwise",
        "t_copy_result",
        "t_copy_result_description_1",
        "t_copy_result_description_2",
        "t_descriptions"
    ],
    data () {
        return {
            status: null,
            rating: 0,
            ports: [],
            shodan: null,
            ipAddress: null,
            title: null,
            description: null,
            descriptions: this.t_descriptions,
            helps: {
                80:     "Public HTTP web server.",
                443:    "Public HTTPS web server.",
                8080:   "Alternative HTTP server port.",
                1900:   "UPnP port. Should be closed from external access."
            }
        }
    },

    created () {
        console.debug("created")
        this.getData()
    },

    activated () {
        console.debug("activated")
        this.getData()
    },

    methods: {
        setTitleAndDescription () {
            console.debug("setTitleAndDescription")

            for (let i = 0; i < this.descriptions.length; i++) {
                const data = this.descriptions[i]

                if (this.rating >= data.rating) {
                    this.title = data.title
                    this.description = data.description
                    break
                }
            }
        },

        getData () {
            console.debug("getData")

            const id = this.$route.params.id

            return this
                .axios({
                    method: "get",
                    url: "/scans/" + id,
                    data: {}
                })
                .then((response) => {
                    console.debug("getData, response=%s", response.data)

                    const status = response.data.status
                    this.status = status

                    if (status === "FINISHED") {
                        this.ports = response.data.result
                        this.shodan = response.data.shodan
                        this.ipAddress = localStorage.getItem("myip")
                        if (this.ports.length > 0) {
                            this.rating = 3
                        }

                        if (this.ports.length > 2) {
                            this.rating = 7
                        }

                        this.setTitleAndDescription()
                    }
                })
                .catch((error) => {
                    this.loading = false
                    alert("Server error: " + error)
                })
        },

        copyLink () {
            console.debug("copyLink")

            try {
                navigator.clipboard.writeText(window.location.href)
                alert("Link copied to clipboard")
            } catch ($e) {
                console.error("Error copying")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/result.scss";
</style>
