<template>
    <p>
        The page you requested could not be found.
    </p>
</template>

<script>
export default {
}
</script>
