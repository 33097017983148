import { createApp } from "vue"
import router from "./router"
import axios from "axios"
import moment from "moment"
import "./assets/fonts/TheSans-B5Plain.otf"
import "./assets/scss/main.scss"
import "bootstrap/scss/bootstrap.scss"
import App from "./App.vue"
import VueCookieAcceptDecline from "vue-cookie-accept-decline"


function initApp () {
    const appInstance = createApp(App)
    appInstance.use(router)
    appInstance.component("vue-cookie-accept-decline", VueCookieAcceptDecline)
    appInstance.config.globalProperties.axios = axios
    appInstance.config.globalProperties.moment = moment
    appInstance.mount("#app")
}

axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.baseURL = "https://portselfscan.ibarry.ch/app"

if (process.env.NODE_ENV === "production") {
    console.log("Using production config")
    initApp()
} else {
    console.log("Using development config")

    axios.defaults.baseURL = process.env.VUE_APP_BACKEND || "http://localhost:8000"
    initApp()
}


