<template>
    <div class="scan">
        <h4>We are scanning your system. Please wait...</h4>

        <div class="progress">
            <progress :value="progress" max="100">{{ progress }}</progress>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            progress: 0,
            interval: null,
            status: "QUEUED",
            ports: []
        }
    },

    created () {
        console.debug("created")
        this.startScan()
    },

    activated () {
        console.debug("activated")
        this.startScan()
    },

    beforeUnmount () {
        console.debug("beforeUnmount")
        this.stopScan()
    },

    beforeRouteLeave (to, from, next) {
        console.debug("beforeRouteLeave")
        this.stopScan()
        next()
    },

    methods: {
        startScan () {
            console.debug("startScan")

            const id = this.$route.params.id

            this.getStatus(id)
                .then(() => {
                    if (this.interval === null && (this.status === "QUEUED" || this.status === "STARTED")) {
                        this.interval = setInterval(() => {
                            this.getStatus(this.$route.params.id)
                        }, 5000)
                    }
                })
        },

        stopScan () {
            console.debug("stopScan")

            if (this.interval !== null) {
                clearInterval(this.interval)
                this.interval = null
            }
        },

        processScanData (id, status, data) {
            console.debug("processScanData, id=%s, status=%s, data=%s", id, status, data)

            switch (status) {
                case "FINISHED":
                case "TIMEOUT":
                case "FAILED":
                    this.stopScan()
                    this.$router.push({name: "Result", params: {id: id}})

                    break

                case "QUEUED":
                case "STARTED":
                    break

                default:
                    break
            }
        },

        getStatus (id) {
            console.debug("getStatus, id=%s", id)

            return this
                .axios({
                    method: "get",
                    url: "/scans/" + id,
                    data: {}
                })
                .then((response) => {
                    console.debug("getStatus, response=%s", response.data)

                    const status = response.data.status

                    this.progress = response.data.progress
                    this.status = status

                    this.processScanData(id, status, response.data)
                })
                .catch((error) => {
                    this.loading = false
                    alert("Server error: " + error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/scan.scss";
</style>
